import {
  Avatar,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React, { useState } from "react";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { baseUrl } from "../../config/config";
import { date_to_display, get_short_string } from "../../utils/constant";
import { useContentSetting } from "../../Hooks/ContentSetting";

export default function Conversations({
  conversationsList,
  handleSelected,
  selectedConversation,
}) {
  const [serachText, setSerachText] = useState("");
  const { userInfo } = useContentSetting();

  const handleChange = (event) => {
    setSerachText(event.target.value);
  };

  const get_conversation = (query) => {
    let dataToFilter = [...conversationsList];
    if (query) {
      const filteredConversations = dataToFilter.filter((conversation) =>
        conversation.members.some(
          (member) =>
            member.user_type === "student" &&
            member.name.toLowerCase().includes(query.toLowerCase())
        )
      );
      return filteredConversations;
    } else {
      return dataToFilter;
    }
  };

  return (
    <div className="chatting-sidebar">
      <div className="search-bar mb-2">
        <FormControl fullWidth>
          <InputLabel htmlFor="outlined-adornment-amount">
            Search User
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            startAdornment={
              <InputAdornment position="start">
                <PersonSearchIcon />{" "}
              </InputAdornment>
            }
            value={serachText}
            label="Search User"
            size="small"
            onChange={handleChange}
          />
        </FormControl>
      </div>
      <div className="chatting-users-list">
        {get_conversation(serachText).map((item, index) => {
          let conversations_user = item.members.find(
            (user) => user.user_type == "student"
          );

          let unread_message_count = item.members.find(
            (user) => user._id == userInfo._id
          ).unread_message_count;

          console.log(unread_message_count, "find_countfind_countfind_count");

          return (
            <>
              <div
                className={`d-flex justify-content-between chatting-user p-2 ${
                  item._id == selectedConversation?._id ? "menuActive" : ""
                }`}
                onClick={() => {
                  handleSelected(item);
                }}
              >
                <div className="d-flex">
                  <Avatar
                    sx={{ width: 45, height: 45 }}
                    src={baseUrl + conversations_user.profile_image}
                  >
                    {conversations_user.name[0]}
                  </Avatar>
                  <div className="user-name">
                    <h5>{conversations_user.name}</h5>
                    {item.last_message && (
                      <p>{get_short_string(item.last_message?.message, 25)}</p>
                    )}
                  </div>
                </div>
                {item.last_message && (
                  <div>
                    <div className="message-date">
                      <span>
                        {date_to_display(item.last_message?.createdAt)}
                      </span>
                    </div>
                    {unread_message_count > 0 && (
                      <div className="text-end">
                        <span className="unread_message_count">
                          {unread_message_count}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <Divider className="sidebar-divider" />
            </>
          );
        })}
      </div>
    </div>
  );
}
