import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { baseUrl } from "../../config/config";
import { students_list_api } from "../../DAL/Students/Students";
import moment from "moment/moment";

export default function StudentsList() {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [studentsList, setStudentsList] = useState([]);

  const get_students_list = async () => {
    const result = await students_list_api();
    if (result.code === 200) {
      const data = result.students_list.map((student) => {
        return {
          ...student,
          table_avatar: {
            src: baseUrl + student.profile_image,
            alt: student.name,
          },
          associate_name: student.associate?.name,
          student_age: moment(student.birth_date, "DD-MM-YYYY").fromNow(true),
        };
      });
      setStudentsList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "roll_number_string", label: "Student ID", alignRight: false },
    { id: "name", label: "Name", alignRight: false },
    {
      id: "gender",
      label: "Gender",
      alignRight: false,
      className: "text-capitalize",
    },
    { id: "student_age", label: "Student Age", alignRight: false },
    {
      id: "table_avatar",
      label: "Profile",
      alignRight: false,
      type: "thumbnail",
      className: "reduce-cell-padding",
    },
  ];

  useEffect(() => {
    get_students_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="d-flex justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Students List</h2>
                </div>
              </div>
              <CustomMUITable TABLE_HEAD={TABLE_HEAD} data={studentsList} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
